export default {
    computed: {
        // asset() {
        //     return `${laravel.baseurl}/public/images/`;
        // },
        user() {
            return this.$store.state.global.user
        },
        site() {
            return this.$store.state.global.site
        },
        status_list() {
            return this.$store.state.global.status_lists
        },
        config() {
            return this.$store.state.global.config
        },
        courier_list() {
            return this.$store.state.global.courier_list
        },
        order_source(){
            return this.$store.state.global.order_source
        },
        cancel_reason(){
            return this.$store.state.global.cancel_reason
        },
        return_reason(){
            return this.$store.state.global.return_reason
        },
        default_image(){
            return this.$store.state.global.default_image
        },
        // global() {
        //     return this.$store.state.global.global
        // },
        // menus() {
        //     return this.$store.state.global.menus
        // },
        // accounts() {
        //     return this.$store.state.global.accounts
        // },
        // permissions() {
        //     return this.$store.state.global.permissions
        // },
        // loggedIn() {
        //     return this.$store.state.auth.user ? true : false;
        // },
        // user() {
        //     return this.$store.state.auth.user
        // },
        // role_name() {
        //     return this.$store.state.auth.role
        // },
        // breadcrumbs() {
        //     return this.$store.state.breadcrumb.levels
        // },
        // noimage() {
        //     return `${laravel.baseurl}/public/noimage.png`;
        // },
        // attach() {
        //     return `${laravel.baseurl}/images/attach.png`;
        // },
    },
}
import {createRouter, createWebHistory} from 'vue-router';

import Dashboard from '@/components/Dashboard.vue';
import Login from '@/views/auth/Login.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: {title:'Home'}
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {title:'Login'}

  },
  // User 
  // { path: '/profile', name: 'profile.create',  meta: {requireAuth: true, title:'Profile'}, component: () => import('./../views/profile/create.vue') },

  // { path: '/about', name: 'About', component: AboutUs, meta: {requireAuth: true, title:'Add User'} }
  { path: '/user', name: 'user.index', meta: {requireAuth: true, title:'All User'},  component: () => import('./../views/user/index.vue') },
  { path: '/user/create', name: 'user.create',  meta: {requireAuth: true, title:'Add User'}, component: () => import('./../views/user/create.vue') },
  { path: '/user/:id', name: 'user.show',  meta: {requireAuth: true, title:'Edit User'}, component: () => import('./../views/user/view.vue') },
  { path: '/user/:id/edit', name: 'user.edit',  meta: {requireAuth: true, title:'Edit User'}, component: () => import('./../views/user/create.vue') },
  { path: '/profile', name: 'profile',  meta: {requireAuth: true, title:'Profile'}, component: () => import('./../views/user/profile.vue') },

  // Customer
  { path: '/customer', name: 'customer.index', meta: {requireAuth: true, title:'All customer'},  component: () => import('./../views/customer/index.vue') },
  { path: '/customer/create', name: 'customer.create',  meta: {requireAuth: true, title:'Add customer'}, component: () => import('./../views/customer/create.vue') },
  { path: '/customer/:id', name: 'customer.show',  meta: {requireAuth: true, title:'Customer'}, component: () => import('./../views/customer/view.vue') },
  { path: '/customer/:id/edit', name: 'customer.edit',  meta: {requireAuth: true, title:'Edit customer'}, component: () => import('./../views/customer/create.vue') },
  { path: '/customer/bulk-create', name: 'customer.bulkcreate',  meta: {requireAuth: true, title:'Bulk Add customer'}, component: () => import('./../views/customer/bulk-create.vue') },

  // product
  { path: '/product', name: 'product.index', meta: {requireAuth: true, title:'All product'},  component: () => import('./../views/product/index.vue') },
  { path: '/product/create', name: 'product.create',  meta: {requireAuth: true, title:'Add product'}, component: () => import('./../views/product/create.vue') },
  { path: '/product/:id', name: 'product.show',  meta: {requireAuth: true, title:'Edit product'}, component: () => import('./../views/product/view.vue') },
  { path: '/product/:id/edit', name: 'product.edit',  meta: {requireAuth: true, title:'Edit product'}, component: () => import('./../views/product/create.vue') },
    
  // order
  { path: '/order', name: 'order.index', meta: {requireAuth: true, title:'All order'},  component: () => import('./../views/order/index.vue') },
  { path: '/order/create', name: 'order.create',  meta: {requireAuth: true, title:'Add order'}, component: () => import('./../views/order/create.vue') },
  { path: '/order/:id', name: 'order.show',  meta: {requireAuth: true, title:'Edit order'}, component: () => import('./../views/order/view.vue') },
  { path: '/order/:id/edit', name: 'order.edit',  meta: {requireAuth: true, title:'Edit order'}, component: () => import('./../views/order/create.vue') },
    
   // category
   { path: '/category', name: 'category.index', meta: {requireAuth: true, title:'All category'},  component: () => import('./../views/category/index.vue') },
   { path: '/category/create', name: 'category.create',  meta: {requireAuth: true, title:'Add category'}, component: () => import('./../views/category/create.vue') },
   { path: '/category/:id', name: 'category.show',  meta: {requireAuth: true, title:'Edit category'}, component: () => import('./../views/category/view.vue') },
   { path: '/category/:id/edit', name: 'category.edit',  meta: {requireAuth: true, title:'Edit category'}, component: () => import('./../views/category/create.vue') },

   // brand
   { path: '/brand', name: 'brand.index', meta: {requireAuth: true, title:'All brand'},  component: () => import('./../views/brand/index.vue') },
   { path: '/brand/create', name: 'brand.create',  meta: {requireAuth: true, title:'Add brand'}, component: () => import('./../views/brand/create.vue') },
   { path: '/brand/:id', name: 'brand.show',  meta: {requireAuth: true, title:'Edit brand'}, component: () => import('./../views/brand/view.vue') },
   { path: '/brand/:id/edit', name: 'brand.edit',  meta: {requireAuth: true, title:'Edit brand'}, component: () => import('./../views/brand/create.vue') },

    // unit
    { path: '/unit', name: 'unit.index', meta: {requireAuth: true, title:'All unit'},  component: () => import('./../views/unit/index.vue') },
    { path: '/unit/create', name: 'unit.create',  meta: {requireAuth: true, title:'Add unit'}, component: () => import('./../views/unit/create.vue') },
    { path: '/unit/:id', name: 'unit.show',  meta: {requireAuth: true, title:'Edit unit'}, component: () => import('./../views/unit/view.vue') },
    { path: '/unit/:id/edit', name: 'unit.edit',  meta: {requireAuth: true, title:'Edit unit'}, component: () => import('./../views/unit/create.vue') },

    // distibutor
    { path: '/distibutor', name: 'distibutor.index', meta: {requireAuth: true, title:'All distibutor'},  component: () => import('./../views/distibutor/index.vue') },
    { path: '/distibutor/create', name: 'distibutor.create',  meta: {requireAuth: true, title:'Add distibutor'}, component: () => import('./../views/distibutor/create.vue') },
    { path: '/distibutor/:id', name: 'distibutor.show',  meta: {requireAuth: true, title:'Edit distibutor'}, component: () => import('./../views/distibutor/view.vue') },
    { path: '/distibutor/:id/edit', name: 'distibutor.edit',  meta: {requireAuth: true, title:'Edit distibutor'}, component: () => import('./../views/distibutor/create.vue') },

    // warehouse
    { path: '/warehouse', name: 'warehouse.index', meta: {requireAuth: true, title:'All warehouse'},  component: () => import('./../views/warehouse/index.vue') },
    { path: '/warehouse/create', name: 'warehouse.create',  meta: {requireAuth: true, title:'Add warehouse'}, component: () => import('./../views/warehouse/create.vue') },
    { path: '/warehouse/:id', name: 'warehouse.show',  meta: {requireAuth: true, title:'Edit warehouse'}, component: () => import('./../views/warehouse/view.vue') },
    { path: '/warehouse/:id/edit', name: 'warehouse.edit',  meta: {requireAuth: true, title:'Edit warehouse'}, component: () => import('./../views/warehouse/create.vue') },

    // supplier
    { path: '/supplier', name: 'supplier.index', meta: {requireAuth: true, title:'All supplier'},  component: () => import('./../views/supplier/index.vue') },
    { path: '/supplier/create', name: 'supplier.create',  meta: {requireAuth: true, title:'Add supplier'}, component: () => import('./../views/supplier/create.vue') },
    { path: '/supplier/:id', name: 'supplier.show',  meta: {requireAuth: true, title:'Edit supplier'}, component: () => import('./../views/supplier/view.vue') },
    { path: '/supplier/:id/edit', name: 'supplier.edit',  meta: {requireAuth: true, title:'Edit supplier'}, component: () => import('./../views/supplier/create.vue') },

    // purchase
    { path: '/purchase', name: 'purchase.index', meta: {requireAuth: true, title:'All purchase'},  component: () => import('./../views/purchase/index.vue') },
    { path: '/purchase/create', name: 'purchase.create',  meta: {requireAuth: true, title:'Add purchase'}, component: () => import('./../views/purchase/create.vue') },
    { path: '/purchase/:id', name: 'purchase.show',  meta: {requireAuth: true, title:'Edit purchase'}, component: () => import('./../views/purchase/view.vue') },
    { path: '/purchase/:id/edit', name: 'purchase.edit',  meta: {requireAuth: true, title:'Edit purchase'}, component: () => import('./../views/purchase/create.vue') },

    // stocktransfer
    { path: '/stocktransfer', name: 'stocktransfer.index', meta: {requireAuth: true, title:'All Stock Transfer'},  component: () => import('./../views/stocktransfer/index.vue') },
    { path: '/stocktransfer/create', name: 'stocktransfer.create',  meta: {requireAuth: true, title:'Add Stock Transfer'}, component: () => import('./../views/stocktransfer/create.vue') },
    { path: '/stocktransfer/:id', name: 'stocktransfer.show',  meta: {requireAuth: true, title:'Edit Stock Transfer'}, component: () => import('./../views/stocktransfer/view.vue') },
    { path: '/stocktransfer/:id/edit', name: 'stocktransfer.edit',  meta: {requireAuth: true, title:'Edit Stock Transfer'}, component: () => import('./../views/stocktransfer/create.vue') },
  
    // stockadjustment
    { path: '/stockadjustment', name: 'stockadjustment.index', meta: {requireAuth: true, title:'All Stock Adjustment'},  component: () => import('./../views/stockadjustment/index.vue') },
    { path: '/stockadjustment/create', name: 'stockadjustment.create',  meta: {requireAuth: true, title:'Add Stock Adjustment'}, component: () => import('./../views/stockadjustment/create.vue') },
    { path: '/stockadjustment/:id', name: 'stockadjustment.show',  meta: {requireAuth: true, title:'Edit Stock Adjustment'}, component: () => import('./../views/stockadjustment/view.vue') },
    { path: '/stockadjustment/:id/edit', name: 'stockadjustment.edit',  meta: {requireAuth: true, title:'Edit Stock Adjustment'}, component: () => import('./../views/stockadjustment/create.vue') },

    // orderreturn
    { path: '/orderreturn', name: 'orderreturn.index', meta: {requireAuth: true, title:'All Order Return'},  component: () => import('./../views/orderreturn/index.vue') },
    { path: '/orderreturn/create', name: 'orderreturn.create',  meta: {requireAuth: true, title:'Add Order Return'}, component: () => import('./../views/orderreturn/create.vue') },
    { path: '/orderreturn/:id', name: 'orderreturn.show',  meta: {requireAuth: true, title:'Edit Order Return'}, component: () => import('./../views/orderreturn/view.vue') },
    { path: '/orderreturn/:id/edit', name: 'orderreturn.edit',  meta: {requireAuth: true, title:'Edit order return'}, component: () => import('./../views/orderreturn/create.vue') },
  
    { path: '/config', name: 'config.create',  meta: {requireAuth: true, title:'Add config'}, component: () => import('./../views/config/create.vue') },
    { path: '/config/:id/edit', name: 'config.edit',  meta: {requireAuth: true, title:'Add config'}, component: () => import('./../views/config/create.vue') },

    // report 
    { path: '/report/full-stock', name: 'full.stock',  meta: {requireAuth: true, title:'Full Stock'}, component: () => import('./../views/report/stock/full-stock.vue') },
    { path: '/report/shortage-alert', name: 'shortage.alert',  meta: {requireAuth: true, title:'Shortage Stock'}, component: () => import('./../views/report/stock/shortage.vue') },

    // logistics
    { path: '/logistic', name: 'logisticcompany.index', meta: {requireAuth: true, title:'All logistic'},  component: () => import('./../views/logistic/index.vue') },
    { path: '/logistic/create', name: 'logisticcompany.create',  meta: {requireAuth: true, title:'Add logistic'}, component: () => import('./../views/logistic/create.vue') },
    { path: '/logistic/:id', name: 'logisticcompany.show',  meta: {requireAuth: true, title:'Edit logistic'}, component: () => import('./../views/logistic/view.vue') },
    { path: '/logistic/:id/edit', name: 'logisticcompany.edit',  meta: {requireAuth: true, title:'Edit logistic'}, component: () => import('./../views/logistic/create.vue') },

    // support
    { path: '/support', name: 'support',  meta: {requireAuth: true, title:'Support'}, component: () => import('./../views/support/index.vue') },

    // billing invoice
    { path: '/billingInvoice', name: 'billingInvoice.index', meta: {requireAuth: true, title:'Billing Invoice List'},  component: () => import('./../views/billingInvoice/index.vue') },
    { path: '/billingInvoice/:id', name: 'billingInvoice.show',  meta: {requireAuth: true, title:'Billing Invoice'}, component: () => import('./../views/billingInvoice/view.vue') },
    { path: '/billingInvoice/:id/edit', name: 'billingInvoice.edit',  meta: {requireAuth: true, title:'Edit Invoice'}, component: () => import('./../views/logistic/create.vue') },

    // SMS
    { path: '/sms', name: 'sms.index', meta: {requireAuth: true, title:'SMS'},  component: () => import('./../views/sms/index.vue') },

];

const router = new createRouter({
    history: createWebHistory(),
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes
});

router.beforeEach(async(to, _, next) => {
  console.log(to.fullPath);
  document.title = to.meta.title

  if (to.meta.requireAuth) {
    if (localStorage.accessToken == undefined) {
      next({name:'Home'})
    }else{
      next()
    }
  }
 
  return next()
})

export default router;

<template>
    <div
      v-if="table.meta && Object.keys(table.meta).length > 0"
      class="global-form-footer"
    >
      <div class="row">
        <div class="col-6">
          <div class="number-of-show">
            <p>
              Showing {{ table.meta.from }} to {{ table.meta.to }} of
              {{ table.meta.total }} entries
            </p>
          </div>
        </div>
  
        <div class="col-6">
            <nav class="Page navigation example">
              <ul class="pagination  justify-content-end">
                  <li 
                      v-if="table.meta.last_page > 1"
                      class="page-item page-prev disabled"
                      :class="table.meta.current_page == 1 ? 'disabled' : ''"
                  >
                      <a class="page-link" @click="get_datas(1)">
                          Prev
                      </a>
                  </li>

                  
                  <template v-for="leftNum in decrement()" :key="leftNum">
                    <li class="page-item">
                      <a  
                          class="page-link"
                          v-if="leftNum > 0"
                          href="javascript:void(0)"
                          @click="get_datas(leftNum)"
                      >
                          <span>{{ leftNum }} </span>
                      </a>
                    </li>
                  </template>
              
          
                  <li class="page-item" :class="(table.meta.current_page == this.$route.query.page) ? 'active' : ''">
                      <!-- Current Page -->
                      <a class="page-link " @click="get_datas(table.meta.current_page)">
                          <span> {{ table.meta.current_page }} </span>
                      </a>
                  </li>
          
                  
                      <!-- Right Numbers -->
                      <template v-for="rightNum in 6" :key="rightNum">
                        <li class="page-item">
                          <a  
                              class="page-link"
                              v-if="
                              table.meta.current_page + rightNum <= table.meta.last_page
                              "
                              href="javascript:void(0)"
                              @click="get_datas(table.meta.current_page + rightNum)"
                          >
                              <span>{{ table.meta.current_page + rightNum }}</span>
                          </a>
                        </li>
                      </template>
                  

                  <li class="page-item page-next">
                      <a class="page-link" href="javascript:void(0);">Next</a>
                  </li>
              </ul>
            </nav>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Pagination",
  
    inject: ["table", "search_data", "model"],
  
    methods: {
      get_datas: function (page_index) {
        let page = page_index ? page_index : this.$route.query.page;
        
        if (this.table.customeRoute) {
          this.$router.push({ name: this.table.customeRouteName, query: { page: page } });
        }else{
          this.$router.push({ name: this.model + ".index", query: { page: page } });
        }
  
        let url = this.model + "?page=" + page;

        this.search_data.page = page;
  
        this.$root.spinner = true;
        this.get_paginate(`${url}`, this.search_data);
      },
  
      // Decrement Page Loop
      decrement() {
        let curentPage = this.table.meta.current_page - 1;
        let arr = [];
        for (var i = 1; i <= 6; i++) {
          if (curentPage > 0) {
            arr.unshift(curentPage--);
          }
        }
        return arr;
      },
    },

    created(){
        console.log('hit paginate', this.table);
    }
  };
  </script>
export default {
    namespaced: true,

    state: () => ({
        user: {},
        site: {},
        global: {},
        config: {},
        courier_list: {},
        order_source: {},
        status_lists: [],
        cancel_reason: [],
        return_reason: [],
        default_image: null
    }),

    mutations: {
        setGlobalData(state, data) {
            state.user = data.user;
            state.site = data.site;
            state.status_lists = data.status_lists;
            state.config = data.config;
            state.courier_list = data.courier_list;
            state.order_source = data.order_source;
            state.cancel_reason = data.cancel_reason;
            state.return_reason = data.return_reason
            state.default_image = data.default_image
        },
    },

    actions: {
        setGlobal(context, data) {
            context.commit('setGlobalData', data);
        }
    },
}
<template>
    <div class="app-admin-wrap layout-sidebar-large">
      <Header v-if="this.$route.name != 'login'"></Header>

      <Sidebar v-if="this.$route.name != 'login'"></Sidebar>

      <div :class="this.$route.name != 'login' ? 'main-content-wrap sidenav-open d-flex flex-column' : '' ">
        <div class="main-content">
          <router-view/>
        </div>
        
      </div>
    </div>
    <!-- Search Ui -->
    <div class="search-ui">
        <div class="search-header">
            <img src="../public/assets/images/logo.png" alt="" class="logo">
            <button class="search-close btn btn-icon bg-transparent float-right mt-2">
                <i class="i-Close-Window text-22 text-muted"></i>
            </button>
        </div>
        <input v-model="globalsearch" @keyup="search()" type="text" placeholder="Phone no" class="search-input" autofocus>
        <div class="search-title">
            <span class="text-muted">Search results</span>
        </div>
        <div class="search-results list-horizontal">
          <div class="col-12" v-if="customer?.name && !this.$root.spinner">
              <div class="card mb-4">
                  <div class="card-body">
                      <div class="card-title">{{ customer.name }}</div>
                      <div class="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
                          <!-- <img class="avatar-md rounded mr-3" src="../../dist-assets/images/faces/2.jpg" alt=""> -->
                          <div class="flex-grow-1">
                              <h6 class="m-0">{{customer.phone}}</h6>
                              <p class="m-0 text-small text-muted">{{ customer.address }}</p>
                              <p class="m-0 text-small text-muted">
                                Delivery Order: 
                                {{ customer.delivery_order }}
                              </p>
                              <p class="m-0 text-small text-muted">
                                <span class="text-success">Success Rate</span>
                                {{ customer.success_rate }}%
                              </p>

                          </div>
                          <div>
                              <!-- <router-link :to="{name:'customer.show', params: {id:customer.id}}" 
                                class="btn btn-outline-primary btn-rounded btn-sm ">
                                View Details
                              </router-link> -->

                              <a @click="viewDetails"
                                class="btn btn-outline-primary btn-rounded btn-sm ">
                                View Details
                              </a>
                          </div>
                      </div>
                     
                  </div>
              </div>
          </div>

          <div v-if="this.$root.spinner" class="text-center">
            <i class="fa fa-spin"></i> dfg
          </div>

        </div>
        
    </div>

    <!-- modal -->
    <div
      class="modal fade"
      id="validateModal"
      tabindex="-1"
      aria-labelledby="validateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-black" id="validateModalLabel">
              Validation Fails
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              v-if="
                $root.validation_errors &&
                Object.keys($root.validation_errors).length > 0
              "
              class="col-12 py-2 mb-3"
              style="background: #fddede"
            >
              <div class="error p-2">
                <span
                  v-for="(err, errIndex) in $root.validation_errors"
                  :key="errIndex"
                  class="text-danger"
                >
                  {{ err[0] }}
                  <br />
                </span>
              </div>
            </div>
  
            <!-- exception_errors -->
            <div
              v-if="
                $root.exception_errors &&
                Object.keys($root.exception_errors).length > 0
              "
              class="col-12 py-2 mb-3"
              style="background: #fddede"
            >
              <div class="error p-2">
                <slot v-if="typeof $root.exception_errors === 'object'">
                  <slot v-for="(err, errIndex) in $root.exception_errors">
                    <span
                      v-if="typeof err === 'string'"
                      :key="errIndex"
                      class="text-danger"
                    >
                      {{ err }}
                      <br />
                    </span>
                  </slot>
                </slot>
                <slot v-else>
                  {{ $root.exception_errors }}
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Header from "./views/partials/Header.vue";
import Sidebar from "./views/partials/Sidebar.vue";


export default {
  name: 'App',
  data(){
    return{
      globalsearch: null,
      customer:null
    }
  },
  components: {
    Header, Sidebar
  },
  methods:{
    search(){
      this.$root.spinner = true
      let phone = this.globalsearch 
      if (phone == undefined) {
          return ;
      }
      if (phone.length == 11) {
          this.axios.get('customer-info', {params: {
                phone, 
                singleData: true
            }
          }).then(res => {
              this.$root.spinner = false
              if (res.status == 200) {
                  this.customer = res.data.data;
              }
          });
      }
    },

    viewDetails(){
      this.$jQuery('.search-close').click();
      // window.location.href = process.env.VUE_APP_BASE_URL+'customer/'+this.customer.id
      this.$router.push({name: 'customer.show', params:{id : this.customer.id}})
    }

  }
}
</script>

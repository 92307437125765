import Vuex from 'vuex'
import auth from './auth'
import division from './system/division'
import warehouse from './system/warehouse'
import global from './global'

export default new Vuex.Store({
  state: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    auth,
    division,
    global,
    warehouse
  }
})

<template>
    <div class="breadcrumb">
        <h1>{{title}}</h1>
        <router-link class="btn btn-sm btn-raised ripple btn-raised-info m-1" 
            v-if="type == 'create'" :to="{name: $parent.model+'.create'}">
            <i class="fa fa-plus"></i> Add
        </router-link>

        <router-link style="font-size: 160%;" 
            v-if="type == 'show'" :to="{name: $parent.model+'.index'}">
            <i class="fa fa-arrow-left"></i>
            <!-- <i class="text-20 i-Left1"></i> -->
        </router-link>

    </div>
    <!-- <div class="separator-breadcrumb border-top"></div> -->
</template>

<script>
    export default{
        name: "Breadcumb",
        props: {
            title: String,
            model: String,
            type: {
                type: String,
                default: null,
            }
        },
    }
</script>


<style scoped>
.breadcrumb {
  display: grid;
  grid-template-columns: 1fr auto; /* Left takes up 1 fraction, right takes up auto space */
}
</style>
// page-create
import Breadcumb from './../components/Breadcumb.vue'
import Input from './../components/form/Input.vue'
import Textarea from './../components/form/Textarea.vue'
import Frame from './../components/form/Frame.vue'
import Radio from './../components/form/Radio.vue'
import Pagination from './../components/table/Pagination.vue'
import VueSelect  from "vue-select"
// import DatePicker from './../components/form/Datepicker.vue'
import File from './../components/form/File.vue'
import Editor from './../components/form/Editor.vue'
import ViewPage from "./../components/table/ViewPage.vue"
import BaseTable from "./../components/table/BaseTable.vue"
import Button from "./../components/form/Button.vue"
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default (app) => {

    app.component('Breadcumb', Breadcumb)
    app.component('Input', Input)
    app.component('Teaxarea', Textarea)
    app.component('Frame', Frame)
    app.component('VueSelect',VueSelect)
    app.component('Radio', Radio)
    app.component('Pagination', Pagination)
    app.component('ViewPage', ViewPage)
    // app.component('DatePicker', DatePicker)
    app.component('File', File)
    app.component('Editor', Editor)
    app.component('Button', Button)
    app.component('BaseTable', BaseTable)
    app.component('Qeditor', QuillEditor)

}
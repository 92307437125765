<template>
    <div class="auth-layout-wrap" style="background-image: url(../../../assets/images/photo-wide-4.jpg)">
    <div class="auth-content">
        <div class="card o-hidden">
            <div class="row">
                <div class="col-md-6">
                    <div class="p-4">
                        <div class="auth-logo text-center mb-4">
                            <!-- Atocommerce -->
                            <img src="../../../public/assets/images/logo.png" alt="">
                        </div>
                        <h1 class="mb-3 text-18">Sign In</h1>
                        <form>
                            <div class="alert alert-danger" v-if="errormessage">
                                {{ errormessage }}
                            </div>
                            <div class="form-group">
                                <label for="email">Email address</label>
                                <input v-model="email" :class="validation.hasError('email') ? 'border-danger' : ''" class="form-control form-control-rounded" id="email" type="email">
                                <small class="message text-danger">{{ validation.firstError('email') }}</small>
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input v-model="password" :class="validation.hasError('password') ? 'border-danger' : ''" class="form-control form-control-rounded" id="password" type="password">
                                <small class="message text-danger">{{ validation.firstError('password') }}</small>

                            </div>
                            <button  @click.prevent="submit()" class="btn btn-rounded btn-primary btn-block mt-2"
                                :disabled='spinner ? true : false'>
                                <span v-if='spinner'>
                                    <i class='fa fa-spinner fa-spin'></i>
                                    processing...
                                </span>
                                <span v-else> Sign In </span>
                            </button>
                        </form>
                        <div class="mt-3 text-center"><a class="text-muted" href="forgot.html">
                                <u>Forgot Password?</u></a></div>
                    </div>
                </div>
                <div class="col-md-6 text-center" style="background-size: cover;background-image: url(../../assets/images/photo-long-3.jpg)">
                    <div class="pr-3 auth-right">
                        <!-- <a class="btn btn-rounded btn-outline-primary btn-outline-email btn-block btn-icon-text" href="signup.html">
                            <i class="i-Mail-with-At-Sign"></i> 
                            Sign up with Email
                        </a>
                        <a class="btn btn-rounded btn-outline-google btn-block btn-icon-text">
                            <i class="i-Google-Plus"></i> 
                            Sign up with Google
                        </a>
                        <a class="btn btn-rounded btn-block btn-icon-text btn-outline-facebook">
                            <i class="i-Facebook-2"></i> 
                            Sign up with Facebook
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import {Validator} from 'simple-vue-validator'

    export default {
        name: 'Login',
   
        data(){
            return {
                email: 'shofiq@gmail.com',
                password: '123456',
                errormessage : null
            }
        },
        methods:{
            submit(){
                this.spinner = true;

                this.$validate().then((res) => {
                    const error = this.validation.countErrors();
                    // If there is an error
                    if (error > 0) {
                        console.log(this.validation.allErrors());
                        this.spinner = false;
                        return false;
                    }

                    // If there is no error
                    if (res) {
                        // code
                        console.log(process.env.VUE_APP_API_BASE_URL);
                        
                        this.axios.post('/login', {email:this.email, password: this.password})
                            .then(res => {
                                if (res.status == 200) {
                                    console.log(res.data);
                                    this.$store.dispatch("auth/loginStore", res.data.data.user);
                                    localStorage.setItem('accessToken', res.data.data.access_token)
                                    localStorage.setItem('user', JSON.stringify(res.data.data.user))
                                    window.location.href = '/';
                                }
                            }).catch(err =>{
                                console.log(err);
                                this.errormessage = err.response.data.message
                                this.spinner = false;
                            })
                    }
                });
            }
        },

        created(){
            let token = localStorage.accessToken;
            if (token != undefined) {
            window.location.href = '/';
            }
        },  
        validators: {
            email: function (value) {
                return Validator.value(value).required('email is require').email();
            },
            password: function (value) {
                return Validator.value(value).required('password is require');
            },
        }
    }

</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive border border-bottom-0">
                        <table class="table table-bordered text-nowrap text-md-nowrap mb-0">
                            <tbody>
                            <tr v-for="(item, name) in data" :key="'A' + name">
                                <slot v-if="!removeField?.includes(name)">
                                    <th class="text-capitalize">
                                    {{ name.replace(new RegExp("_", "g"), " ") }}
                                    </th>
                                    <td>
                                    <span v-if="name == 'created_at'">{{
                                        item
                                    }}</span>
                                    <span v-else-if="name == 'updated_at'">{{
                                        item
                                    }}</span>
                                    <span v-else-if="name == 'date'">{{ item }}</span>
                                    <span v-else-if="typeof item == 'object'">
                                        <slot v-if="objectField?.find(item => item.name == name)">
                                            {{ item[objectField.find(item => item.name == name).field] }}
                                        </slot>
                                    </span>
                                    <span v-else>{{ item }}</span>
                                    </td>
                                </slot>
                            </tr>
                            </tbody>
                        </table>
                        <br>
                        <h3 v-if="customtitle" >{{customtitle}}</h3>
                        <slot v-if="customtitle" name="customsection"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
    props: ["data", 'customtitle'],

    inject: ['removeField', 'objectField']
  };
  </script>
  
  <style>
  </style>
import axios from '../../axios.js'
export default {
    namespaced: true,

    state: () => ({
        warehouses: null,
        useCache: false
    }),

    mutations: {
        setWarehouse: function (state, data) {
            state.warehouses = data;
        },
    },

    actions: {
        getWarehouse({commit, state}) {
            if (state.useCache) { return }

            axios.get(`${process.env.VUE_APP_API_BASE_URL}/warehouse?allData=true`).then((res) => {
                console.log('hit store', res.data.data);
                commit('setWarehouse', res.data.data);
            }).catch((e) => {
                console.log(e);
            })
            state.useCache = true
        }
    }
}
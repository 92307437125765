export function getToken() {
    return localStorage.getItem('accessToken');
}

export function setToken(token) {
    localStorage.setItem('accessToken', token);
}

export function removeToken() {
    localStorage.removeItem('accessToken');
}

export function isTokenExpired() {
    try {
      const decoded = JSON.parse(window.atob(localStorage.getItem('accessToken').split(".")[1]));
      if (decoded.exp * 1000 < Date.now()) {
        return true;
      }
      return false;
    } catch (error) {
      return true;
    }

}

// export function getRemainingTime(token) {
//     try {
//       const decoded = jwtDecode(token);
//       return decoded.exp * 1000 - Date.now();
//     } catch (error) {
//       return 0;
//     }
// }
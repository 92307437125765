<template>
    <div  :class="getClass()">
      <div class="form-group">
        <label v-if="title" class="form-label">{{ title }}<sup v-if="req" class="text-danger">*</sup></label>

        <input class="form-control"
            :type="type"
            @input="$emit('update:modelValue', $event.target.value)"
            :value="modelValue"
            :class="errorClass()"
            :placeholder="placeholder ?? title"
            :disabled="disabled"
            :readonly="readonly"
            :name="fieldName"
        >

        <span v-if="validate.hasError(this.field)" class="invalid-feedback">
            {{ validate.firstError(this.field) }}
        </span>

        <span class="text-danger" v-if="$parent.error">
            {{  $parent.error[this.getField()] }}
        </span>
      </div>
    </div>

  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: [String, Number],
      },
      placeholder:{
        type: String,
      },
      title: {
        type: String,
      },
      field: {
        type: String,
      },
      type: {
        type: String,
        default: "text",
      },
      col: {
        type: String,
      },
      req: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
  
    inject: ["validate"],
  
    computed: {
      fieldName() {
        return this.field.split(".").pop();
      },
    },
  
    methods: {
      getClass() {
        let col = this.col ? this.col : 3;
        let className = "col-lg-" + col + " ";

        if (this.req) {
          if (this.validate.hasError(this.field)) {
            className += "has-danger";
          } else if (this.modelValue) {
            className += "has-success";
          }
        }
        return className;
      },
      getField(){
        return this.field.split(".").pop();
      },
      getIcon() {
        let errorStatus = this.validate.hasError(this.field);
        if (errorStatus && this.req) {
          return "far fa-times-circle danger-icon";
        } else if (this.modelValue) {
          return "bi bi-check-lg success-icon";
        }
      },
      errorClass() {
        let className = " ";
        if (this.req) {
          if (this.validate.hasError(this.field)) {
            className += "is-invalid state-invalid";
          } else if (this.modelValue) {
            className += "is-valid state-valid";
          }
        }
        return className;
      },
    },
  };
  </script>
import { createToaster } from "@meforma/vue-toaster";

export default {
  install(app) {
    app.config.globalProperties.$toast = function (message, type){
      const toaster = createToaster({ /* options */ });
      if (type == 'success') {
        toaster.success(message);
      }
      if (type == 'warning') {
        toaster.warning(message);
      }
      if (type == 'error') {
        toaster.error(message);
      }
      if (type == 'info') {
        toaster.info(message);
      }
    };
  }
};
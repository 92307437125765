import { createApp, h } from 'vue'
import jQuery from 'jquery'
import App from './App.vue'
import router from './router'
import axios from './axios.js';
import store from './store'
import custom from './plugin/custom.js'
import crud from "./mixins/crud.js"
import global from "./mixins/global.js"
import custom_mixin from "./mixins/custom.js"
import toast_plugin from './plugin/toast'
import { isTokenExpired, removeToken } from "./services/auth.js";
import "vue-select/dist/vue-select.css";


window.$ = window.jQuery = require('jquery');

import validate from 'simple-vue-validator'
const Validator = validate.Validator
window.Validator = Validator

const app = createApp({
    render: () => h(App),

    data(){
        return{
            baseurl: process.env.VUE_APP_BASE_URL,
            spinner: false,
            tableSpinner: false,
            submit: false,
            validation_errors: {},
            exception_errors: {},
        }
    },

    methods: {
        getInitializeSystems() {
            axios.get('/init-system',{headers: {Authorization: 'bearer '+localStorage.accessToken}}).then(res => {
                this.$store.dispatch("global/setGlobal", res.data.data);
            });
        }
    },  
    mounted() {
        console.log(this.$store.state.auth.user);
        console.log('token expired : ', isTokenExpired());

        if (!isTokenExpired()) {
            this.getInitializeSystems();
        }

        if (isTokenExpired()) {
            removeToken();
            this.$store.dispatch("auth/loginStore", null);
            this.$router.push({name: 'login'})
        }
    }

});
app.config.globalProperties.axios = axios;
app.config.globalProperties.Validator = Validator;

const JQueryPlugin = {
    install(app) {
      app.config.globalProperties.$jQuery = jQuery;
    }
};
app.use(JQueryPlugin);
app.use(validate);
app.use(router);
app.use(store);
app.use(custom);
app.mixin(crud);
app.use(toast_plugin);
app.mixin(custom_mixin)
app.mixin(global)
app.mount('#app');

window.spinner = false
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
window.axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';

let token = localStorage.accessToken;
if (token) {
    window.axios.defaults.headers.common["Authorization"] = 'bearer '+localStorage.accessToken;
} 

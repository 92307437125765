<template>
        <div class="side-content-wrap">
            <div class="sidebar-left open rtl-ps-none" data-perfect-scrollbar="" data-suppress-scroll-x="true">
                <ul class="navigation-left">
                    <li class="nav-item">
                        <router-link class="nav-item-hold" :to="{name:'Home'}">
                            <i class="nav-icon i-Bar-Chart"></i>
                            <span class="nav-text">Dashboard</span>
                        </router-link>
                        <!-- <div class="triangle"></div> -->
                    </li>
                    <li class="nav-item" data-item="orders">
                        <a class="nav-item-hold" href="#">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="nav-text">
                                Orders
                            </span>
                        </a>
                        <div class="triangle"></div>
                    </li>

                    <li class="nav-item" data-item="inventory">
                        <a class="nav-item-hold" href="#">
                            <i class="nav-icon i-Suitcase"></i>
                            <span class="nav-text">Inventory</span>
                        </a>
                        <div class="triangle"></div>
                    </li>

                    <li class="nav-item" data-item="purchase">
                        <a class="nav-item-hold" href="#">
                            <i class="nav-icon i-Shopping-Basket"></i>
                            <span class="nav-text">Purchase</span>
                        </a>
                        <div class="triangle"></div>
                    </li>

                    <li class="nav-item" data-item="products">
                        <a class="nav-item-hold" href="#">
                            <i class="nav-icon i-Library"></i>
                            <span class="nav-text">
                                Products
                            </span>
                        </a>
                        <div class="triangle"></div>
                    </li>
                    <li class="nav-item" data-item="customers">
                        <a class="nav-item-hold" href="#">
                            <i class="nav-icon i-Administrator"></i>
                            <span class="nav-text">Customers</span>
                        </a>
                        <div class="triangle"></div>
                    </li>
                    <li class="nav-item" data-item="supplier">
                        <a class="nav-item-hold" href="#">
                            <i class="nav-icon i-Computer-Secure"></i>
                            <span class="nav-text">Supplier</span>
                        </a>
                        <div class="triangle"></div>
                    </li>

                    <li class="nav-item" data-item="config">
                        <a class="nav-item-hold" href="#">
                            <i class="nav-icon i-Gear"></i>
                            <span class="nav-text">Configurations</span>
                        </a>
                        <div class="triangle"></div>
                    </li>
                </ul>
            </div>
            <div class="sidebar-left-secondary rtl-ps-none" data-perfect-scrollbar="" data-suppress-scroll-x="true">
              
                <ul class="childNav" data-parent="supplier">
                    <li class="nav-item">
                        <router-link :to="{name:'supplier.index'}">
                            <i class="nav-icon i-Receipt-4"></i>
                            <span class="item-name">Supplier List</span>
                        </router-link>
                        <router-link :to="{name:'supplier.create'}">
                            <i class="nav-icon i-Receipt-4"></i>
                            <span class="item-name">Supplier Create</span>
                        </router-link>
                    </li>
                </ul>
               
              
                <ul class="childNav" data-parent="config">
                    <li class="nav-item">
                        <router-link :to="{name:'logisticcompany.index'}">
                            <i class="nav-icon i-Crop-2"></i>
                            <span class="item-name">Logistics</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'warehouse.index'}">
                            <i class="nav-icon i-Crop-2"></i>
                            <span class="item-name">Warehouse</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'config.create'}">
                            <i class="nav-icon i-Crop-2"></i>
                            <span class="item-name">Config</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="childNav" data-parent="orders">
                    <li class="nav-item">
                        <router-link :to="{name:'order.create'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Order Create</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'order.index'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Order List</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'orderreturn.index'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Order Return List</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'orderreturn.create'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Order Return Create</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="childNav" data-parent="inventory">
                    <li class="nav-item">
                        <router-link :to="{name:'full.stock'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Full Stock Report</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'shortage.alert'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Shortage Report</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'stocktransfer.index'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Stock Transfer List</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'stocktransfer.create'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Stock Transfer Create</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'stockadjustment.index'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Stock Adjustment List</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name:'stockadjustment.create'}">
                            <i class="nav-icon i-Car-Items"></i>
                            <span class="item-name">Stock Adjustment Create</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="childNav" data-parent="purchase">
                    <li class="nav-item">
                        <router-link :to="{name: 'purchase.index'}">
                            <i class="nav-icon i-Bell1"></i>
                            <span class="item-name">Purchase List</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'purchase.create'}">
                            <i class="nav-icon i-Bell1"></i>
                            <span class="item-name">Purchase Create</span>
                        </router-link>
                    </li>

                </ul>
                <ul class="childNav" data-parent="products">
                    <li class="nav-item">
                        <router-link :to="{name: 'product.index'}">
                            <i class="nav-icon i-Bell1"></i>
                            <span class="item-name">Products</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'category.index'}">
                            <i class="nav-icon i-Bell1"></i>
                            <span class="item-name">Category</span>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link :to="{name: 'brand.index'}">
                            <i class="nav-icon i-Bell1"></i>
                            <span class="item-name">Brand</span>
                        </router-link>
                    </li>
                    
                </ul>
                <ul class="childNav" data-parent="customers">
                    <li class="nav-item">
                        <router-link :to="{name: 'customer.create'}">
                            <i class="nav-icon i-Bell1"></i>
                            <span class="item-name">Customer Create</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'customer.index'}">
                            <i class="nav-icon i-Bell1"></i>
                            <span class="item-name">Customer List</span>
                        </router-link>
                    </li>
                </ul>
                
            </div>
            <div class="sidebar-overlay"></div>
        </div>
</template>


<script>
    export default {
        name: 'Sidebar',
    }
</script>

export default {
    methods: {
        dateFormat(input_date){
            const date = new Date(input_date); // Months are 0-indexed, so 2 is March

            // Define options for toLocaleDateString
            const options = { year: 'numeric', month: 'short', day: 'numeric' };

            // Format the date
            const formattedDate = date.toLocaleDateString('en-US', options);

            return formattedDate; // Output: March 19, 2024
        },
        number_format(number = 0){
            return number?.toLocaleString('en-US');
        }
        
    }
}
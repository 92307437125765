<template>
    <button 
        :type='type' 
        :class="customclass"
        class="col-2 btn btn-primary btn-block btn-rounded"
        :disabled='spinner ? true : false'>
            <span v-if='$root.submit'>
                <i class='fa fa-spinner fa-spin'></i>
                processing...
            </span>
            <span v-else> {{ title ?? 'Submit' }} </span>
    </button>
</template>

<script>
    export default{
        props: {
            title: {
                type: String,
                default: null,
            },
            type: {
                type: String,
                default: 'submit',
            },

            customclass: {
                type: String,
                default: '',
            },
        }
    }
</script>
<template>
  <div>
    <vue-quill v-model="content" :options="editorOptions"></vue-quill>
  </div>
</template>

<script>
import { VueQuill } from '@vueup/vue-quill';

export default {
  name: 'QuillEditor',
  components: {
    VueQuill
  },
  data() {
    return {
      content: '', // You can initialize this with some default content if needed
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link', 'image']
          ]
        }
      }
    };
  },
  methods: {
    getContent() {
      return this.content;
    },
    setContent(newContent) {
      this.content = newContent;
    }
  }
};
</script>

<style>
@import '~quill/dist/quill.snow.css';
</style>

<template>
  <div class="">
    <div class="row pt-2">
      <div class="col-12 col-lg-6 col-sm-12">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                  <!-- <label class="input-group-text" for="theme_selector">Themes</label>
                  <select class="custom-select col-lg-6 col-sm-12" id="theme_selector">
                      <option value="default">default</option>
                      <option value="arrows">arrows</option>
                      <option value="circles">circles</option>
                      <option value="dots">dots</option>
                  </select> -->
              </div>
          </div>
      </div>
      <div class="col-12 col-lg-6 col-sm-12 d-flex flex-column flex-sm-row align-items-center"><span class="m-auto"></span>
          <div class="btn-group col-lg-8 col-sm-12 pl-sm-3" role="group">
              <button @click="getDashboard('today')" class="btn btn-secondary" id="prev-btn" type="button">Today</button>
              <button @click="getDashboard('last_seven_day')" class="btn btn-secondary" id="next-btn" type="button">Last 7 Days</button>
              <button @click="getDashboard('last_thirty_day')" class="btn btn-secondary" id="reset-btn" type="button">Last 30 Days</button>
              <button @click="getDashboard('life_time')" class="btn btn-secondary" id="reset-btn" type="button">Life Time</button>
          </div>
      </div>
    </div>
    <section class="ul-widget-stat-s1 pt-2">
      <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div class="card-body text-center"><i class="i-Car-Items"></i>
                      <div class="content">
                          <p class="text-muted mt-2 mb-0">Orders</p>
                          <p class="text-primary text-24 line-height-1 mb-2">{{ data.count?.order_count }}</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div class="card-body text-center"><i class="i-Money-2"></i>
                      <div class="content" style="margin: 3px !important; margin-left: 15px !important; max-width:100% !important">
                          <p class="text-muted mt-2 mb-0">Sales Amount</p>
                          <p class="text-primary text-24 line-height-1 mb-2"> {{ this.config.currency_symbol}}{{ data.count?.sales_amount }}</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div class="card-body text-center"><i class="i-Feedburner"></i>
                      <div class="content">
                          <p class="text-muted mt-2 mb-0">Cancel</p>
                          <p class="text-primary text-24 line-height-1 mb-2">{{ data.count?.cancel_order }}</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div class="card-body text-center"><i class="i-File-Horizontal-Text"></i>
                      <div class="content">
                          <p class="text-muted mt-2 mb-0">Return</p>
                          <p class="text-primary text-24 line-height-1 mb-2">{{ data.count?.return_order }}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_status">
                <div class="card-title">Order Status</div>
                <Pie :data="data.order_status" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_source">
                <div class="card-title">Order Source </div>
                <Bar :data="data.order_source" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.cancel_order">
                <div class="card-title">Cancel Reason </div>
                <Pie :data="data.cancel_order" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.return_order">
                <div class="card-title">Return Reason </div>
                <Pie :data="data.return_order" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_trend">
                <div class="card-title">Order Trend </div>
                <Line :data="data.order_trend" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.top_10_location">
                <div class="card-title">Top 10 Location </div>
                <Bar :data="data.top_10_location" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.top_10_location_by_value">
                <div class="card-title">Top 10 Location By Value </div>
                <Bar :data="data.top_10_location_by_value" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.order_status_cycle_time">
                <div class="card-title">Order Status Cycle Time </div>
                <Bar :data="data.order_status_cycle_time" :options="options" />
            </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="card chart-card mb-4">
            <div class="card-body" v-if="data.top_10_product">
                <div class="card-title">Top 10 Product </div>
                <Bar :data="data.top_10_product" :options="options" />
            </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import { Chart as ChartJS, Title, ArcElement, Tooltip, Legend, BarElement,
  CategoryScale,   LineElement, PointElement,
  LinearScale } from 'chart.js'
  import { Bar } from 'vue-chartjs'
  import { Pie } from 'vue-chartjs'
  import { Line } from 'vue-chartjs'

  // import { Bar } from 'vue-chartjs'

  ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement,PointElement, Title, ArcElement, Tooltip, Legend)

export default {
  name: 'Dashboard',
  components: {
    Pie, Bar, Line
  },
  data(){
    return{
      data:{
        count:{}
      },
      options: {},
      search_data:{}
    }
  },

  methods:{
    getDashboard(type){
      this.axios.get('dashboard', {params:{type}}).then(res => {
        this.data = res.data.data
        console.log(res);
      })
    }
  },

  created(){
    //  this.data = {
    //   labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
    //   datasets: [
    //     {
    //       backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
    //       data: [40, 20, 80, 10]
    //     }
    //   ]
    // }

    this.options = {
      responsive: true,
      maintainAspectRatio: false
    }

    this.getDashboard('today');    

  }
}
</script>

<style scoped>
  .chart-card {
    height: 400px; /* or any desired fixed height */
  }

  .card-body {
      height: 100%;
      position: relative;
  }

  canvas{
    height:310px !important;
  }

</style>